<template>
  <v-container fluid>
    <div class="app">
      <v-row>
        <v-col cols="12">
          <BackButton :handler="backToReports" />
        </v-col>
      </v-row>

      <div class="user-wrapper mt-4">
        <div
          class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-50"
        >
          <v-row>
            <v-col cols="12">
              <div class="" style="background-color: rgba(17, 42, 70, 0.04)">
                <p class="pa-4 font-extrabold text-xl text-blue">Filters</p>
              </div>
            </v-col>
          </v-row>

          <v-row class="">
            <v-col cols="2">
              <v-autocomplete
                outlined
                placeholder="Cashier Name"
                v-model="searchParams.cashier_id"
                item-value="id"
                item-text="name"
                :items="casherNames"
                :loading="isLoading"
                class="q-autocomplete shadow-0"
                hide-details
                dense
              >
              </v-autocomplete>
            </v-col>

            <v-col cols="2" style="padding-right: 0">
              <v-menu
                v-model="menu1"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    outlined
                    v-model="date1Formatted"
                    readonly
                    v-on="on"
                    class="q-text-field shadow-0"
                    hide-details
                    dense
                    style="background-color: #ffffff"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="searchParams.from_date"
                  @input="menu1 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="2">
              <v-menu
                v-model="menu2"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="date2Formatted"
                    outlined
                    style="background-color: #ffffff"
                    readonly
                    v-on="on"
                    class="q-text-field shadow-0"
                    hide-details
                    dense
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="searchParams.to_date"
                  @input="menu2 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="2">
              <v-flex xs1 class="ml-1">
                <v-btn color="#00b0af" height="40" dark @click="getReport"
                  >Go</v-btn
                >
              </v-flex>
            </v-col>
          </v-row>

          <div class="md-card md-theme-default rounded-5 shadow-0">
            <div class="search_customer">
              <div class="header_title pl-4 pr-4">
                <v-row>
                  <v-col>Sales Log Report </v-col>
                  <v-spacer></v-spacer>
                  <v-col md="4" class="text-right">
                    <v-menu offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          class="export-button mt-2"
                          elevation="0"
                          height="40"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <SvgIcon text="Export Report">
                            <template v-slot:icon>
                              <ExportIcon />
                            </template>
                          </SvgIcon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item @click="reportDownload('pdf')">
                          <v-list-item-icon>
                            <v-icon>mdi-file-pdf-box</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>Export as PDF</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="reportDownload('excel')">
                          <v-list-item-icon>
                            <v-icon>mdi-file-excel-box</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>Export as Excel</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-col>
                </v-row>
              </div>
            </div>

            <div class="md-card-content">
              <div>
                <div
                  class="md-content md-table md-theme-default table_borders"
                  table-header-color="orange"
                  value
                >
                  <div
                    class="md-content md-table-content md-scrollbar md-theme-default"
                  >
                    <table class="logTable">
                      <thead class="md-card-header shadow-0 stickr">
                        <tr>
                          <th class="md-table-head">
                            <div
                              class="md-table-head-container md-ripple md-disabled"
                            >
                              <div class="md-table-head-label">Timestamp</div>
                            </div>
                          </th>

                          <th class="md-table-head">
                            <div
                              class="md-table-head-container md-ripple md-disabled"
                            >
                              <div class="md-table-head-label cursor-pointer">
                                Mode of sale
                              </div>
                            </div>
                          </th>
                          <th class="md-table-head">
                            <div
                              class="md-table-head-container md-ripple md-disabled"
                            >
                              <div class="md-table-head-label cursor-pointer">
                                Invoice Number
                              </div>
                            </div>
                          </th>
                          <th class="md-table-head">
                            <div
                              class="md-table-head-container md-ripple md-disabled"
                            >
                              <div class="md-table-head-label cursor-pointer">
                                Receipt Number
                              </div>
                            </div>
                          </th>
                          <th class="md-table-head">
                            <div
                              class="md-table-head-container md-ripple md-disabled"
                            >
                              <div class="md-table-head-label cursor-pointer">
                                Cashier Name
                              </div>
                            </div>
                          </th>
                          <th class="md-table-head">
                            <div
                              class="md-table-head-container md-ripple md-disabled"
                            >
                              <div class="md-table-head-label cursor-pointer">
                                Sales person Name
                              </div>
                            </div>
                          </th>
                          <th class="md-table-head">
                            <div
                              class="md-table-head-container md-ripple md-disabled"
                            >
                              <div class="md-table-head-label">
                                Customer Name
                              </div>
                            </div>
                          </th>
                          <th class="md-table-head">
                            <div
                              class="md-table-head-container md-ripple md-disabled"
                            >
                              <div class="md-table-head-label cursor-pointer">
                                Start Date
                              </div>
                            </div>
                          </th>
                          <th class="md-table-head">
                            <div
                              class="md-table-head-container md-ripple md-disabled"
                            >
                              <div class="md-table-head-label cursor-pointer">
                                End Date
                              </div>
                            </div>
                          </th>
                          <th class="md-table-head">
                            <div
                              class="md-table-head-container md-ripple md-disabled"
                            >
                              <div class="md-table-head-label">Type</div>
                            </div>
                          </th>
                          <th class="md-table-head">
                            <div
                              class="md-table-head-container md-ripple md-disabled"
                            >
                              <div class="md-table-head-label">Product</div>
                            </div>
                          </th>
                          <th class="md-table-head">
                            <div
                              class="md-table-head-container md-ripple md-disabled"
                            >
                              <div class="md-table-head-label">
                                Payment method
                              </div>
                            </div>
                          </th>

                          <th class="md-table-head">
                            <div
                              class="md-table-head-container md-ripple md-disabled"
                            >
                              <div class="md-table-head-label">Card Number</div>
                            </div>
                          </th>
                          <th class="md-table-head">
                            <div
                              class="md-table-head-container md-ripple md-disabled"
                            >
                              <div class="md-table-head-label">
                                Taxable Amount
                              </div>
                            </div>
                          </th>
                          <th class="md-table-head">
                            <div
                              class="md-table-head-container md-ripple md-disabled"
                            >
                              <div class="md-table-head-label">Tax amount</div>
                            </div>
                          </th>

                          <th class="md-table-head">
                            <div
                                class="md-table-head-container md-ripple md-disabled"
                            >
                              <div class="md-table-head-label">Discount Type</div>
                            </div>
                          </th>

                          <th class="md-table-head">
                            <div
                                class="md-table-head-container md-ripple md-disabled"
                            >
                              <div class="md-table-head-label">
                                Total (before Discount)
                              </div>
                            </div>
                          </th>
                          <th class="md-table-head">
                            <div
                                class="md-table-head-container md-ripple md-disabled"
                            >
                              <div class="md-table-head-label">Discounted Amount</div>
                            </div>
                          </th>
                          <th class="md-table-head">
                            <div
                              class="md-table-head-container md-ripple md-disabled"
                            >
                              <div class="md-table-head-label">
                                Net amount
                              </div>
                            </div>
                          </th>
                          <th class="md-table-head">
                            <div
                              class="md-table-head-container md-ripple md-disabled"
                            >
                              <div class="md-table-head-label">Action</div>
                            </div>
                          </th>
                          <!-- <th class="md-table-head">
                            <div
                              class="
                                  md-table-head-container md-ripple md-disabled
                                "
                            >
                              <div class="md-table-head-label">
                                Payment type
                              </div>
                            </div>
                          </th>
                          <th class="md-table-head">
                            <div
                              class="
                                  md-table-head-container md-ripple md-disabled
                                "
                            >
                              <div class="md-table-head-label">
                                Number of installments
                              </div>
                            </div>
                          </th>
                          <th class="md-table-head">
                            <div
                              class="
                                  md-table-head-container md-ripple md-disabled
                                "
                            >
                              <div class="md-table-head-label">
                                Ordinal Number of installment
                              </div>
                            </div>
                          </th> -->
                          <th class="md-table-head">
                            <div
                              class="md-table-head-container md-ripple md-disabled"
                            >
                              <div class="md-table-head-label">
                                Installment information
                              </div>
                            </div>
                          </th>
                          <th class="md-table-head">
                            <div
                              class="md-table-head-container md-ripple md-disabled"
                            >
                              <div class="md-table-head-label">Remarks</div>
                            </div>
                          </th>
                        </tr>
                      </thead>

                      <tbody
                        v-if="membershipSalesReport.length"
                        :set="((totalPrice = 0), (totalTax = 0), (total = 0))"
                      >
                        <tr
                          class="md-table-row"
                          :key="`${lndex}`"
                          v-for="(data, lndex) in membershipSalesReport"
                        >
                          <td class="md-table-cell">
                            <div class="md-table-cell-container text-center">
                              {{ data.timestamp | timeStamp }}
                            </div>
                          </td>
                          <td class="md-table-cell">
                            <div class="md-table-cell-container text-center">
                              {{ data.source_name }}
                            </div>
                          </td>
                          <td class="md-table-cell">
                            <div class="md-table-cell-container text-center">
                              {{ data.invoice_seq_no }}
                            </div>
                          </td>
                          <td class="md-table-cell">
                            <div class="md-table-cell-container text-center">
                              {{ data.receipt_no }}
                            </div>
                          </td>
                          <td class="md-table-cell">
                            <div class="md-table-cell-container text-center">
                              {{ data.cashier_name }}
                            </div>
                          </td>
                          <td class="md-table-cell">
                            <div class="md-table-cell-container text-center">
                              {{
                                data.sales_person_name
                                  ? data.sales_person_name
                                  : "-"
                              }}
                            </div>
                          </td>
                          <td class="md-table-cell">
                            <div class="md-table-cell-container text-center">
                              {{ data.customer_name }}
                            </div>
                          </td>
                          <td class="md-table-cell">
                            <div class="md-table-cell-container text-center">
                              {{
                                data.mem_start_dates
                                  ? data.mem_start_dates
                                  : data.tr_start_date
                                  ? data.tr_start_date
                                  : "-"
                              }}
                            </div>
                          </td>
                          <td class="md-table-cell">
                            <div class="md-table-cell-container text-center">
                              {{
                                data.mem_end_dates
                                  ? data.mem_end_dates
                                  : data.tr_end_date
                                  ? data.tr_end_date
                                  : "-"
                              }}
                            </div>
                          </td>
                          <td class="md-table-cell">
                            <div class="md-table-cell-container text-center">
                              {{ data.product_type }}
                            </div>
                          </td>
                          <td class="md-table-cell">
                            <div class="md-table-cell-container text-center">
                              {{ data.product_name }}
                            </div>
                          </td>
                          <td class="md-table-cell">
                            <div class="md-table-cell-container text-center">
                              {{ data.payment_method_name }}
                            </div>
                          </td>
                          <td class="md-table-cell">
                            <div class="md-table-cell-container text-center">
                              {{ data.card_number ? data.card_number : "-" }}
                            </div>
                          </td>
                          <td
                            class="md-table-cell"
                            :set="(totalPrice += data.price)"
                          >
                            <div class="md-table-cell-container text-center">
                              {{ data.price | toCurrency }}
                            </div>
                          </td>
                          <td
                            class="md-table-cell"
                            :set="(totalTax += data.tax)"
                          >
                            <div class="md-table-cell-container text-center">
                              {{ data.tax | toCurrency }}
                            </div>
                          </td>

                          <td class="md-table-cell">
                            <div class="md-table-cell-container text-center">
                              {{ data.discount_type ? data.discount_type : "-" }}
                            </div>
                          </td>
                          <td class="md-table-cell">
                            <div class="md-table-cell-container text-center">
                              {{ data.actual_total ? data.actual_total : "-" }}
                            </div>
                          </td>
                          <td class="md-table-cell">
                            <div class="md-table-cell-container text-center">
                              {{ data.discount_amount ? data.discount_amount : "-" }}
                            </div>
                          </td>
                          <td
                            class="md-table-cell"
                            :set="(total += data.total)"
                          >
                            <div class="md-table-cell-container text-center">
                              {{ data.total | toCurrency }}
                            </div>
                          </td>
                          <td class="md-table-cell">
                            <div class="md-table-cell-container text-center">
                              {{ data.status }}
                            </div>
                          </td>
                          <!-- <td class="md-table-cell">
                            <div class="md-table-cell-container text-center">
                              {{ data.payment_method_name }}
                            </div>
                          </td>
                          <td class="md-table-cell">
                            <div class="md-table-cell-container text-center">
                              {{ data.payment_method_name }}
                            </div>
                          </td>
                          <td class="md-table-cell">
                            <div class="md-table-cell-container text-center">
                              {{ data.payment_method_name }}
                            </div>
                          </td> -->
                          <td class="md-table-cell">
                            <div class="md-table-cell-container text-center">
                              {{ data.order_notes ? data.order_notes : "-" }}
                            </div>
                          </td>
                          <td class="md-table-cell">
                            <div class="md-table-cell-container text-center">
                              {{
                                data.invoice_notes ? data.invoice_notes : "-"
                              }}
                            </div>
                          </td>
                        </tr>
                        <tr class="md-table-row grand_total_bg sticky_bottom">
                          <td class="md-table-cell" colspan="13">
                            <div class="md-table-cell-container text-center">
                              <strong>GRAND TOTAL</strong>
                            </div>
                          </td>
                          <td class="md-table-cell">
                            <div class="md-table-cell-container text-center">
                              <strong>
                                {{
                                  "AED " + parseFloat(totalPrice).toFixed(2)
                                }}</strong
                              >
                            </div>
                          </td>
                          <td class="md-table-cell">
                            <div class="md-table-cell-container text-center">
                              <strong>
                                {{
                                  "AED " + parseFloat(totalTax).toFixed(2)
                                }}</strong
                              >
                            </div>
                          </td>
                          <td class="md-table-cell" colspan="3"></td>
                          <td class="md-table-cell">
                            <div class="md-table-cell-container text-center">
                              <strong>
                                {{
                                  "AED " + parseFloat(total).toFixed(2)
                                }}</strong
                              >
                            </div>
                          </td>
                          <td class="md-table-cell" colspan="3"></td>
                        </tr>
                      </tbody>
                      <tbody v-else>
                        <tr>
                          <td :colspan="20">
                            <v-card-text class="pa-8 text-center">
                              <h3>No data found!</h3>
                            </v-card-text>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <v-pagination
                  class="mt-3 new-pagination"
                  v-model="page"
                  :length="totalPages"
                  total-visible="7"
                ></v-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
import moment from "moment";
import BackButton from "@/components/Common/BackButton.vue";
import SvgIcon from "@/components/Image/SvgIcon.vue";
import ExportIcon from "@/assets/images/misc/export-icon.svg";
export default {
  components: { ExportIcon, SvgIcon, BackButton },
  data() {
    return {
      membershipSalesReport: [],
      services: [],
      menu1: false,
      menu2: false,
      fileName: null,
      searchParams: {
        venueServiceId: null,
        productTypeId: null,
        from_date: moment().subtract(30, "days").format("YYYY-MM-DD"),
        to_date: moment().format("YYYY-MM-DD"),
        venueServiceIds: [],
      },
      totalPages: 0,
      page: 1,
      casherNames: [{ name: "All" }],
      isLoading: false,
    };
  },
  watch: {
    page: {
      immediate: true,
      handler() {
        this.getReport();
      },
    },
  },
  computed: {
    date1Formatted() {
      return moment(this.searchParams.from_date, "YYYY-MM-DD").format(
        "Do MMM YYYY"
      );
    },
    date2Formatted() {
      return moment(this.searchParams.to_date, "YYYY-MM-DD").format(
        "Do MMM YYYY"
      );
    },
    dateLength() {
      return this.membershipSalesReport.length;
    },
    rowSpan() {
      return parseInt(this.membershipSalesReport.length / 4);
    },
    venueServices() {
      return this.$store.getters.getVenueServices.data;
    },
    productTypes() {
      return this.$store.getters.getProductTypes.data;
    },
  },
  mounted() {
    if (this.$store.getters.getVenueServices.status == false) {
      this.$store.dispatch("loadVenueServices");
    }
    if (this.$store.getters.getProductTypes.status == false) {
      this.$store.dispatch("loadProductTypes");
    }
    this.getCasherName();
  },
  methods: {
    getCasherName() {
      this.isLoading = true;
      this.$http
        .get(`venues/reports/cashier-names`)
        .then((response) => {
          if (response.status == 200 && response.data.status) {
            this.isLoading = false;
            this.casherNames = response.data.data;
            this.casherNames.unshift({ name: "All" });
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    toggle(type, field) {
      this.$nextTick(() => {
        if (this.searchParams[field].length == this[type].length) {
          this.searchParams[field] = [];
        } else {
          this.searchParams[field] = this[type].map((item) => item.id);
        }
      });
      this.$forceUpdate();
    },
    getIcon(type, field) {
      let icon = "mdi-checkbox-blank-outline";

      if (this.searchParams[field].length == this[type].length) {
        icon = "mdi-close-box";
      }
      if (
        this.searchParams[field].length > 0 &&
        this.searchParams[field].length != this[type].length
      )
        icon = "mdi-minus-box";

      return icon;
    },
    backToReports() {
      this.$router.push({
        name: "Reports",
      });
    },
    getReport() {
      let url = this.getFilterUrlData();
      if (!url) return;
      this.showLoader("Loading");
      this.$http
        .get(`venues/reports/sales-log-report${url}&page=${this.page}`)
        .then((response) => {
          if (response.status == 200) {
            const data = response.data;
            this.membershipSalesReport = data.data;
            this.totalPages = data.total_pages;
            this.hideLoader();
            this.$forceUpdate();
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    reportDownload(type) {
      let link = "";
      if (type == "pdf") {
        link = "sales-log-report-pdf/download";
      } else if (type == "excel") {
        link = "sales-log-report-excel/download";
      }
      this.showLoader("Preparing report..");
      let url = this.getFilterUrlData();
      if (!url) return;
      this.$http
        .get(`venues/reports/` + link + `${url}`, {
          responseType: "blob",
        })
        .then((response) => {
          if (response.status == 200) {
            this.downloadReport(response, this.fileName, type);
            this.hideLoader();
          }
        })
        .catch((error) => {
          this.hideLoader();
          this.errorChecker(error);
        });
    },
    getFilterUrlData() {
      if (
        moment(this.searchParams.from_date, "YYYY-MM-DD").isAfter(
          moment(this.searchParams.to_date, "YYYY-MM-DD")
        )
      ) {
        this.showError("To date must be greater than or equal to from date");
        return;
      }
      let url = `?from_date=${this.searchParams.from_date}&to_date=${this.searchParams.to_date}`;

      if (
        this.searchParams.cashier_id &&
        this.searchParams.cashier_id != "All" &&
        this.searchParams.cashier_id != "Online"
      ) {
        url += "&cashier_id=" + this.searchParams.cashier_id;
      }

      this.fileName =
        this.searchParams.from_date + "-to-" + this.searchParams.to_date;
      let venueService = null;
      let productType = null;
      if (this.searchParams.venueServiceIds.length > 0) {
        url += `&venue_service_id=${this.searchParams.venueServiceIds}`;
        venueService = this.findValueByKey(
          this.venueServices,
          this.searchParams.venueServiceIds
        );
      }

      if (this.searchParams.productTypeId) {
        url += "&product_type_id=" + this.searchParams.productTypeId.id;
        productType = this.findValueByKey(
          this.productTypes,
          this.searchParams.productTypeId.id
        );
      }

      if (productType !== null)
        this.fileName += "-" + productType.name.replace(/\s/g, "");
      if (venueService !== null)
        this.fileName += "-" + venueService.name.replace(/\s/g, "");
      if (this.fileName != null)
        this.fileName = "Sales-log-report-" + this.fileName;
      else this.fileName = "Sales-log-report";
      return url;
    },
    toggleSelect() {
      this.$nextTick(() => {
        if (
          this.venueServices.length == this.searchParams.venueServiceIds.length
        ) {
          this.searchParams.venueServiceIds = [];
        } else {
          this.searchParams.venueServiceIds = this.venueServices.map(
            (item) => item.venue_service_id
          );
        }
      });
      setTimeout(() => {
        this.loadMemberships();
      });
    },
    getServiceIcon() {
      if (this.searchParams.venueServiceIds.length == 0)
        return "mdi-checkbox-blank-outline";
      if (this.venueServices.length == this.searchParams.venueServiceIds.length)
        return "mdi-close-box";
      return "mdi-minus-box";
    },
    findValueByKey(opts, key) {
      var match = opts.filter(function (opt) {
        return opt.id === key;
      });
      return match[0] ? match[0] : null;
    },
  },
};
</script>

<style scoped>
.logTable tbody tr:hover {
  transform: scale(1) !important;
  -webkit-transform: scale(1) !important;
  -moz-transform: scale(1) !important;
  box-shadow: 0px 0px 5px 2px rgba(13, 84, 139, 0.3) !important;
  -webkit-box-shadow: 0px 0px 5px 2px rgba(13, 84, 139, 0.3) !important;
  -moz-box-shadow: 0px 0px 5px 2px rgba(13, 84, 139, 0.3) !important;
  z-index: 1;
}

.md-card-header {
  background-color: #edf7f8; /* Set color opacity to 10% */
}
</style>
